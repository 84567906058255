import React, { useState, useEffect } from 'react';
import { useAuth } from '../Auth/AuthContext';

const Wagering = () => {
    const { email, fwinBackendUrl } = useAuth();
    const [bonus, setBonus] = useState([]);
    const [error, setError] = useState(null);

    const fetchBonus = async () => {
        try {
            setError(null); // Clear previous errors
            const response = await fetch(`${fwinBackendUrl}/admin/user/wagering?email=${email}`);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch bonus');
            }

            const data = await response.json();
            setBonus(data.bonus || []); // Ensure bonus is an array
        } catch (err) {
            setBonus([]); // Clear any previous bonus data
            setError(err.message); // Handle errors
        }
    };

    useEffect(() => {
        if (email && fwinBackendUrl) {
            fetchBonus();
        }
    }, [email, fwinBackendUrl]);

    return (
        <div>
            <div className="max-w-screen-xl">
                <div className="max-w-lg">
                    <h3 className="my-4 text-left text-gray-800 text-lg font-bold">
                        Bonuses History
                    </h3>
                </div>
                {error && <div className="text-red-500 my-2">{error}</div>} {/* Error message display */}
                <div className="shadow-sm border rounded-lg overflow-x-auto">
                    <table className="w-full table-auto text-sm text-left">
                        <thead className="bg-gray-200 text-gray-600 font-medium border-b">
                            <tr>
                                <th className="py-3 px-6">Bonus Type</th>
                                <th className="py-3 px-6">Date</th>
                                <th className="py-3 px-6">Amount</th>
                                <th className="py-3 px-6">Expiry</th>
                                <th className="py-3 px-6">Wagering Multiplier</th>
                                <th className="py-3 px-6">Wagering</th>
                                <th className="py-3 px-6">Wagering Progress</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 divide-y">
                            {bonus.length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="px-6 py-4 text-center">No bonus history available</td>
                                </tr>
                            ) : (
                                bonus.map((item, idx) => (
                                    <tr key={idx}>
                                        <td className="px-6 py-4 whitespace-nowrap">{item.type}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className="inline-flex items-center text-sm font-medium text-teal-500">
                                              {typeof item.amount === 'number' ? `€${item.amount.toFixed(2)}` : ''}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-red-500">
                                            {(() => {
                                                const expiryDate = new Date(item.expiry); // Parse expiry date string
                                                const currentDate = new Date(); // Get the current date
                                                const timeDifference = expiryDate - currentDate; // Difference in milliseconds
                                                // Convert milliseconds to days
                                                const daysLeft = Math.floor(timeDifference / (1000 * 3600 * 24));
                                                return daysLeft > 0 ? `${daysLeft} days left` : 'Expired'; // Display days left or 'Expired'
                                            })()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">{item.wageringMultiplier}x</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {typeof item.wagering === 'number' ? `€${item.wagering.toFixed(2)}` : ''}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {item.wagering === item.wageringProgress ? (
                                                <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-teal-100 text-teal-800 dark:bg-teal-800/30 dark:text-teal-500">
                                                    Completed
                                                </span>
                                            ) : (
                                                item.wageringProgress
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div> 
        </div>
    );
};

export default Wagering;
