import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../Components/Auth/AuthContext';  
import Datepicker from "tailwind-datepicker-react"

const options = {
	autoHide: true,
	todayBtn: false,
	clearBtn: true,
	clearBtnText: "Clear",
	maxDate: new Date("2030-01-01"),
	minDate: new Date("1950-01-01"),
	theme: {
		background: "bg-white",
		todayBtn: "",
		clearBtn: "",
		icons: "",
		text: "",
		disabledText: "",
		input: "",
		inputIcon: "",
		selected: "",
	},
	icons: {
		// () => ReactElement | JSX.Element
		prev: () => <span><svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg></span>,
		next: () => <span><svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></span>,
	},
	datepickerClassNames: "top-10 right-0",
	defaultDate: new Date("2022-01-01"),
	language: "en",
	disabledDates: [],
	weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
	inputNameProp: "date",
	inputIdProp: "date",
	inputPlaceholderProp: "Select Date",
	inputDateFormatProp: {
		day: "numeric",
		month: "long",
		year: "numeric"
	}
}
// Function to handle tab change


const Bonus = () => {
    const { fwinBackendUrl} = useAuth();
    const [show, setShow] = useState(false);
    const [date, setDate] = useState();
    const [activeTab, setActiveTab] = useState('all');
    const [summary, setSummary] = useState({
        signup: 0,
        first: 0,
        deposit: 0,
      });
    const [allBonuses, setAllBonuses] = useState(0);
    const [transactions, setTransactions] = useState([]);
    
    useEffect(() => {
        fetch(`${fwinBackendUrl}/bonus/summary`)  // Replace with your API endpoint
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            setSummary(data);  // Save the response data to state
            const totalBonuses = data.signup + data.first + data.deposit;
            setAllBonuses(totalBonuses);  // Set the total sum in state
          })
          .catch((error) => {
          });
      }, []);
    useEffect(() => {
        fetch(`${fwinBackendUrl}/bonus/all`)  // Replace with your actual API endpoint
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to fetch transactions');
            }
            return response.json();
          })
          .then((data) => {
            setTransactions(data);  // Save the response data to the state

          })
          .catch((err) => {

          });
      }, []);

	const handleChange = (selectedDate) => {
		setDate(selectedDate);
	}
	const handleClose = (state) => {
		setShow(state)
	}
  const handleTabChange = (tab) => {
        setActiveTab(tab);
        // Reset pagination when switching tabs
        
    };
  const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Extract YYYY-MM-DD
    };
    const filteredTransactions = transactions.filter((item) => {
      const matchesType =
        activeTab === 'all' ? true : item.type === activeTab;
      const matchesDate = date
        ? formatDate(item.createdAt) === formatDate(date)
        : true;
      return matchesType && matchesDate;
    });
    


    return (
        <div className="">
            <section>
                <div className='space-y-5'>
                    <div className="flex items-center gap-2">
                        <div className="flex h-6 w-6 items-center justify-center rounded-md border">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-club h-4 w-4"
                            >
                                <path d="M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z"></path>
                                <path d="M12 17.66L12 22"></path>
                            </svg>
                        </div>
                        <h3>Bonus System</h3>
                    </div>
                </div>
           </section>
           <section>
    <div className='py-4'>
          <div className='grid sm:grid-cols-1 md:grid-cols-4 gap-4'>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <div className='flex justify-center pb-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                                </svg>
                                <span className='px-4 text-gray-500'>All bonuses</span>
                </div>
                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>&#8364; {allBonuses}</h5>
                </a>
               
              </div>
            </div>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <div className='flex justify-center pb-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.99 14.993 6-6m6 3.001c0 1.268-.63 2.39-1.593 3.069a3.746 3.746 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043 3.745 3.745 0 0 1-3.068 1.593c-1.268 0-2.39-.63-3.068-1.593a3.745 3.745 0 0 1-3.296-1.043 3.746 3.746 0 0 1-1.043-3.297 3.746 3.746 0 0 1-1.593-3.068c0-1.268.63-2.39 1.593-3.068a3.746 3.746 0 0 1 1.043-3.297 3.745 3.745 0 0 1 3.296-1.042 3.745 3.745 0 0 1 3.068-1.594c1.268 0 2.39.63 3.068 1.593a3.745 3.745 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.297 3.746 3.746 0 0 1 1.593 3.068ZM9.74 9.743h.008v.007H9.74v-.007Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                    </svg>
                                <span className='px-4 text-gray-500'>SignUp Bonuses</span>
                </div>
                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>&#8364; {summary.signup}</h5>
                </a>

              </div>
            </div>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <div className='flex justify-center pb-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 11.625h4.5m-4.5 2.25h4.5m2.121 1.527c-1.171 1.464-3.07 1.464-4.242 0-1.172-1.465-1.172-3.84 0-5.304 1.171-1.464 3.07-1.464 4.242 0M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                    </svg>
                                <span className='px-4 text-gray-500'>First Deposit Bonuses</span>
                </div>
                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>&#8364; {summary.first}</h5>
                </a>

              </div>
            </div>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <div className='flex justify-center pb-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                    </svg>
                                <span className='px-4 text-gray-500'>Deposit bonuses</span>
                </div>
                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>&#8364;{summary.deposit}</h5>
                </a>

              </div>
            </div>
            
          </div>
    </div>
           </section>
           <section>
            <div className='flex py-4'>
            <div className='flex w-full bg-white border border-gray-200 rounded-lg shadow '>
                <div className='flex gap-8 '>
                        <div className='p-6  flex flex-col space-y-4'>
                        <h3 className='text-left pb-4'>General Bonuses</h3>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>Signup Bonus</p>
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                            €&nbsp;10.00
                            </span>
                        </div>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>First Deposit Bonus wagering</p>
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                            250%
                            </span>
                        </div>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>Deposit Bonus wagering</p>
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                            100%
                            </span>
                        </div>

                        </div>
                </div>
                </div>
            </div>
               
           </section>
           <section>
            <div className='p-6 bg-white border border-gray-200 rounded-lg shadow'>
                <div className='pb-4'>
                    <div className='flex justify-between'>
                        <div>
                        <div className='flex items-center'>
                        <div className='themeSwitcherTwo p-2 shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-gray-200'>
                            <input
                                type='checkbox'
                                className='sr-only'
                                checked={activeTab === 'all'}
                                onChange={() => handleTabChange(activeTab === 'live' ? 'all' : 'live')}
                            />
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'all' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('all')}
                            >
                                All
                            </span>
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'signup' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('signup')}
                            >
                                Signup
                            </span>
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'deposit' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('deposit')}
                            >
                                Deposit
                            </span>
                           
                        </div>
                    </div>
                        </div>
                        <div className='relative'>
                        <Datepicker options={options} onChange={handleChange} show={show} setShow={handleClose} />
                        </div>
                    </div>
                    
                </div>
                <div>
                    <div class="relative h-[400px] overflow-y-auto rounded-md border">
                    <table className="w-full table-fixed caption-bottom text-sm divide-y divide-gray-200">
  <thead className="sticky top-0 z-10 bg-background">
    <tr className="w-full border-b">
      <th className="px-6 py-4 text-left text-sm font-medium">Date</th>
      <th className="px-6 py-4 text-left text-sm font-medium">ID</th>
      <th className="px-6 py-4 text-left text-sm font-medium">Player</th>
      <th className="px-6 py-4 text-left text-sm font-medium">Bonus</th>
      <th className="px-6 py-4 text-left text-sm font-medium">Amount</th> 
      <th className="px-6 py-4 text-left text-sm font-medium">Balance</th>
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">
    {filteredTransactions.map((transaction, index) => (
      <tr key={index}>
        <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{new Date(transaction.createdAt).toLocaleString()}</td>
        <td className="px-6 py-4 text-left text-sm font-medium">{transaction.userDetails.userId}</td>
        <td className="px-6 py-4 text-left text-sm font-medium">{transaction.userDetails.firstname}</td>
        <td className="px-6 py-4 text-left text-sm font-medium">{transaction.type}</td>
        <td className="px-6 py-4 text-left text-sm font-medium">€{transaction.amount}</td>
        <td className="px-6 py-4 text-left text-sm font-medium">€{transaction.userDetails.balance}</td>
      </tr>
    ))}
  </tbody>
</table>
                </div>
            </div>
            </div>

           </section>
        </div>
    );
};

export default Bonus;