import React, { useEffect, useState } from 'react';




const Terms = () => { 



  
  return (
    <> 
     <div className=" text-gray-800 min-h-screen py-10 px-6 sm:px-12">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-4xl font-bold text-center mb-6 text-blue-600">Terms and Conditions</h1>
        <p className="text-gray-600 text-center mb-10">
          Last updated: <span className="font-medium">07/06/2024</span>
        </p>

        <div className="space-y-8">
          <section className='text-left'>
            <h2 className="text-2xl font-semibold mb-4">1. Eligibility</h2>
            <ul className="space-y-2 list-disc pl-6">
              <li>
                <span className="font-medium">Legal Age:</span> You must be at least 18 years old or the legal age for
                online gambling in your jurisdiction, whichever is greater.
              </li>
              <li>
                <span className="font-medium">Jurisdiction:</span> It is your responsibility to ensure that accessing
                and using our platform is legal in your country or region.
              </li>
                <li>
                  <span className="font-medium">Prohibited Territories:</span> Residents of Cuba
                  Iran,
                  North Korea,
                  Syria,
                  Russia,
                  Uniter States
                  are not permitted to use our services.
                </li>
            </ul>
          </section>

          <section className='text-left'>
            <h2 className="text-2xl font-semibold mb-4">2. Account Registration</h2>
            <ul className="space-y-2 list-disc pl-6">
              <li>
                <span className="font-medium">Accuracy of Information:</span> You must provide accurate and complete
                information during registration.
              </li>
              <li>
                <span className="font-medium">One Account Rule:</span> Only one account per person is allowed. Multiple
                accounts may result in suspension or termination.
              </li>
              <li>
                <span className="font-medium">Security:</span> You are responsible for maintaining the confidentiality
                of your login details.
              </li>
            </ul>
          </section>

          <section className='text-left'>
            <h2 className="text-2xl font-semibold mb-4">3. Responsible Gambling</h2>
            <ul className="space-y-2 list-disc pl-6">
              <li>
                <span className="font-medium">Self-Exclusion:</span> If you need to limit your gambling, we offer
                self-exclusion tools. Contact customer support for assistance.
              </li>
              <li>
                <span className="font-medium">Responsible Play:</span> We encourage responsible gambling. 
                Visit <a className='underline' href='https://www.gambleaware.org/'> GambleAware </a> for support and advice.
              </li>
            </ul>
          </section>

          <section className='text-left'>
            <h2 className="text-2xl font-semibold mb-4">4. Deposits and Withdrawals</h2>
            <ul className="space-y-2 list-disc pl-6">
              <li>
                <span className="font-medium">Payment Methods:</span> We accept deposits via USDT-TRC20. Ensure
                the payment method belongs to you.
              </li>
              <li>
                <span className="font-medium">Withdrawals:</span> Withdrawals are subject to verification checks and
                processing times as detailed in our Withdrawal Policy.
              </li>
              <li>
                <span className="font-medium">Limits:</span> Daily, weekly, or monthly limits may apply, depending on
                your account settings.
              </li>
            </ul>
          </section>

            <section className='text-left'>
              <h2 className="text-2xl font-semibold mb-4">5. Bonuses and Promotions</h2>
              <ul className="space-y-2 list-disc pl-6">
                <li>
                  <span className="font-medium">General Bonus Types:</span>
                  <ul className="space-y-2 list-decimal pl-8">
                    <li>
                      <span className="font-medium">Signup Bonus:</span> A 10 EUR bonus is awarded upon successful signup. This bonus is subject to a 20x wagering requirement. The maximum cashout from the signup bonus is limited to 10x the bonus amount (100 EUR).
                    </li>
                    <li>
                      <span className="font-medium">First Deposit Bonus:</span> A 250% bonus is granted on your first deposit, with a minimum deposit of 25 EUR and a maximum deposit of 500 EUR. This bonus is subject to a 25x wagering requirement. The maximum cashout from the first deposit bonus is limited to 10x the bonus amount.
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="font-medium">Wagering Requirement:</span>
                  <ul className="space-y-2 list-decimal pl-8">
                    <li>The bonus amount must be wagered the required number of times before funds can be withdrawn. For the signup bonus, the wagering requirement is 20x the bonus amount, and for the first deposit bonus, it is 25x the bonus amount.</li>
                    <li>Wagering progress is tracked in real-time. Once the required wagering amount is fulfilled for a bonus, the bonus will be considered completed.</li>
                  </ul>
                </li>
                <li>
                  <span className="font-medium">Withdrawal Conditions:</span>
                  <ul className="space-y-2 list-decimal pl-8">
                    <li>You are not permitted to withdraw any funds from your account until all wagering requirements are fully completed.</li>
                    <li>If any wagering requirement is still pending, the withdrawal option will be disabled. Only after fulfilling the full wagering requirement for all active bonuses can you withdraw funds from your account.</li>
                    <li>After completing the wagering requirements, you are allowed to withdraw any amount in your account, including the bonus funds (subject to maximum cashout limits).</li>
                  </ul>
                </li>
                <li>
                  <span className="font-medium">Bonus Expiry:</span>
                  <ul className="space-y-2 list-decimal pl-8">
                    <li>Bonuses are valid for a limited period and will expire if the wagering requirements are not completed within the specified timeframe. Upon expiry, any remaining bonus balance will be forfeited.</li>
                  </ul>
                </li>
                <li>
                  <span className="font-medium">Max Cashout:</span>
                  <ul className="space-y-2 list-decimal pl-8">
                    <li>For both the signup and first deposit bonuses, the maximum cashout is 10x the bonus amount. Any winnings exceeding this limit will be forfeited.</li>
                  </ul>
                </li>
              </ul>
            </section>


         
        </div>

        <footer className="mt-10 text-center text-gray-500 text-sm">
          <p>
            For questions, contact us at <span className="text-blue-500 underline" > <a href="mailto:support@fwin-team.freshchat.com">support@fwin-team.freshchat.com</a> </span>.
          </p>
          <p className="mt-2">
            <span className="font-medium">Fwin </span> encourages responsible gambling. Please play within
            your limits.
          </p>
        </footer>
      </div>
    </div>
    </> 
 
  );
}

export default Terms; 
