import React, { Component, useState } from 'react';
import Homepage from './GamesSelect/Homepage';
import Coinflip from './GamesSelect/Coinflip';
import Crash from './GamesSelect/Crash';
import Dice from './GamesSelect/Dice';
import Roulette from './GamesSelect/Roulette';
import Slots from './GamesSelect/Slots';
import Register from './Auth/Register';
import Live from './GamesSelect/Live';
import Help from './GamesSelect/Help';
import Terms from './GamesSelect/TermsPage';

class GamesSection extends Component { 

    constructor(props) {
        super(props);
        this.state = {

        };

    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedGame !== this.props.selectedGame) {
          // Scroll to the top when selectedGame changes
          window.scrollTo(0, 0);
        }
      }
    
    render() {
        const { selectedGame, onGameChange  } = this.props; 
        const { isMenuOpen } = this.state;
        return (
            <div className='px-4 py-5 lg:px-8'>
                {selectedGame === 'homepage' && <Homepage onGameChange={onGameChange} />} 
                {selectedGame === 'coinflip' && <Coinflip />}
                {selectedGame === 'crash' && <Crash />}
                {selectedGame === 'dice' && <Dice />}
                {selectedGame === 'roulette' && <Roulette />}
                {selectedGame === 'slots' && <Slots onGameChange={onGameChange} />}
                {selectedGame === 'live' && <Live />}
                {selectedGame === 'help' && <Help />} 


                {selectedGame === 'register' && <Register onGameChange={onGameChange} />} 
                {selectedGame === 'terms' && <Terms />} 
            </div>
        );
    }
}


export default GamesSection; 