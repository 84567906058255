// SliderComponent.js
import React from 'react';
import Slider from "react-slick";
import './proEmbla.css';

import evoplay from '../../../Assets/proEvoplay.svg'; 
import pragmatic from '../../../Assets/proPragmatic.svg'; 
import habanero from '../../../Assets/proHabanero.svg'; 
import pgsoft from '../../../Assets/pgsoft.png'; 
import boongo from '../../../Assets/boongo.jpg'; 
import playson from '../../../Assets/playson.webp'; 
import cq9 from '../../../Assets/cq9.png'; 
import toptrend from '../../../Assets/toptrend.png'; 
import dreamtech from '../../../Assets/dreamtech.webp'; 










const ProviderCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  };

  return (
    <div >
      <Slider {...settings}>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer border-2 hover:bg-indigo-600 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={evoplay}
            alt="Slide 1"
            style={{  height: '20px', width: 'auto'}}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer hover:bg-indigo-600 border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={pragmatic}
            alt="Slide 2"
            style={{ height: '30px', width: 'auto' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer hover:bg-indigo-600 border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={habanero}
            alt="Slide 3"
            style={{ height: '30px', width: 'auto' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer hover:bg-indigo-600 border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={pgsoft}
            alt="Slide 4"
            style={{ height: '30px', width: 'auto' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer hover:bg-indigo-600 border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={boongo}
            alt="Slide 5"
            style={{ height: '30px', width: 'auto' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer hover:bg-indigo-600 border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={playson}
            alt="Slide 6"
            style={{ height: '20px', width: '90px' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer hover:bg-indigo-600 border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={cq9}
            alt="Slide 7"
            style={{ height: '20px', width: 'auto' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer hover:bg-indigo-600 border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={toptrend}
            alt="Slide 8"
            style={{ height: '20px', width: '75px' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='transition-transform duration-200 transform hover:scale-95 cursor-pointer hover:bg-indigo-600 border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={dreamtech}
            alt="Slide 9"
            style={{ height: '20px', width: '75px' }}
          />
        </div>
        </div>
      </Slider>
    </div>
  );
}

export default ProviderCarousel;
