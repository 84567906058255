// src/components/ServerStatus.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth  } from './Auth/AuthContext'; 

const ServerStatus = () => {
  const [status, setStatus] = useState('Checking...');
  const [responseTime, setResponseTime] = useState(null); 
  const { fwinBackendUrl } = useAuth();

  useEffect(() => {
    const checkServerStatus = async () => {
      const start = Date.now();

      try {
        // Replace with your server's API endpoint
        const response = await axios.get(`${fwinBackendUrl}/ping`); 

        if (response.status === 200) {
          setStatus('Online');
          setResponseTime(Date.now() - start);
        } else {
          setStatus('Offline');
        }
      } catch (error) {
        setStatus('Offline');
      }
    };

    // Check server status on component mount
    checkServerStatus();

    // Optional: Polling the server status every 10 seconds
    const interval = setInterval(checkServerStatus, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Server Status</h1>
      <p style={styles.status}>
        Status: <strong className='text-green-500'>{status}</strong>
      </p>
      {responseTime !== null && status === 'Online' && (
        <p style={styles.responseTime}>
          Response Time: <strong>{responseTime} ms</strong>
        </p>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  title: {
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  status: {
    fontSize: '1.5rem',
    color: '#333',
  },
  responseTime: {
    fontSize: '1rem',
    color: '#666',
  },
};

export default ServerStatus;
