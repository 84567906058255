import React, { createContext, useState, useEffect, useContext } from 'react';
import Pagination from './Pagination';
import '../test.css';
import FrameLauncher from '../iFrame/iFrame';
import axios from 'axios'; 
import { useAuth } from '../Auth/AuthContext'; 






 

const Slots = ({ onGameChange }) => {
    const slotsPerPage = 30;
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const { currentUser, fwinBackendUrl, updateBalance } = useAuth();
    const [error, setError] = useState('');
    const [gameList, setGameList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [provider, setProvider] = useState('All');

    const handleGameChange = () => {
      onGameChange("register");
  };
  const filteredGameList = gameList.filter(
    (game) => provider === 'All' || game.game_provider_code === provider 
  );
    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredGameList.length / slotsPerPage);
   
    // Calculate the index of the first and last slot for the current page
    const indexOfLastSlot = currentPage * slotsPerPage;
    const indexOfFirstSlot = indexOfLastSlot - slotsPerPage;
    // Get the current slots
    const currentSlots = gameList
    .filter((game) => provider === 'All' || game.game_provider_code === provider)
    .slice(indexOfFirstSlot, indexOfLastSlot);


    const handleSlotClick = (slot) => {
      const userEmail = currentUser?.email;
      if (!userEmail) {
          handleGameChange();
          return;
      }
      console.log(userEmail);
      setSelectedSlot({
        id: slot.games__id,
        url: slot.games__game_image,
        provider: slot.game_provider_code,
        game_code: slot.games__game_code
      });
      // Send POST request to the server using Axios
      axios.post(`${fwinBackendUrl}/api/game-launch`, {
          email: userEmail, // Replace with actual email value
          provider_code: slot.game_provider_code,
          game_code: slot.games__game_code
      })
      .then(response => {
          console.log('Success:', response.data);
          if (response.data.status === 1) {
              setIframeUrl(response.data.launch_url); // Save the launch_url in state
              setIsVisible(true); // Show the iframe
          }
      })
      .catch(error => {
          console.error('Error:', error);
      });
  };
    const closeIframe = () => {
        setIsVisible(false);
        updateBalance();
  };
  const fetchGames = async () => {
    try {
      const response = await axios.get(`${fwinBackendUrl}/admin/gameslist/slots`); // Replace with your API URL
      setGameList(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
    const slotsData = gameList.map((game, index) => ({
      id: index + 1,
      image: game.SRC,
      provider : game.Provider,
      Game_Code : game.Game_Code,
    }));
  };
  // Use useEffect to fetch data on component mount
  useEffect(() => {
    fetchGames();
  }, []); 
  const uniqueProviders = [...new Set(gameList.map((game) => game.game_provider_code))];
  

    return ( 
        <div className='flex-1 overflow-y-auto overflow-x-hidden bg-background'>
            <div className='flex'>
                <div className='grow py-4 mb-2'>
                    <div className='flex'>
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <rect width="24" height="24" rx="4" fill="#6EE7B7"></rect>
                                 <rect x="6" y="6" width="4" height="10" rx="1" fill="#2C5282"></rect>
                                 <rect x="11" y="6" width="4" height="10" rx="1" fill="#2C5282"></rect>
                                 <rect x="16" y="6" width="4" height="10" rx="1" fill="#2C5282"></rect>
                               </svg>
                               <span className='font-sans text-md font-bold px-2'>Slots Games</span>
                               <div>
  <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500 relative">
    <svg class="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
      <polyline points="16 7 22 7 22 13"></polyline>
    </svg>
    97.23% &nbsp;RTP
    <span class="flex absolute top-0 end-0 size-3 -mt-1.5 -me-1.5">
      <span class="animate-ping absolute inline-flex size-full rounded-full bg-rose-400 opacity-75 dark:bg-rose-600"></span>
      <span class="relative inline-flex rounded-full size-3 bg-rose-500"></span>
    </span>
  </span>
</div> 

                    </div>
                </div>
                {/* Dropdown for provider filter */}
                <div className="pb-4">
                    <select
                        value={provider}
                        onChange={(e) => setProvider(e.target.value)}
                        className="border rounded-2xl p-2 border-slate-200"
                    >
                        <option value="All">All Providers</option>
                        {uniqueProviders.map((prov) => (
                            <option key={prov} value={prov}>
                                {prov}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <main className=''>
                <div className="">
                    <div className="grid grid-cols-3 md:grid-cols-6 gap-3 justify-between pb-8">
                        {currentSlots.map((slot) => (
                            <div 
                            key={slot.games__id}
                             className="shadow-lg cursor-pointer relative  flex items-center justify-center rounded-xl overflow-hidden"
                             onClick={() => handleSlotClick(slot)} // Handle click event
                            >
                                <img src={slot.games__game_image} alt={slot.games__game_name} className="w-full h-full object-cover" />
                               
                            </div>
                        ))}
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </main>
            {isVisible && (
             <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50">
              <FrameLauncher iframeUrl={iframeUrl}   closeIframe={closeIframe} />
              </div>
            )}
        </div>
    );
};

export default Slots;
