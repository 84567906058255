import React, { Component, useState, useEffect} from 'react';  
import axios from 'axios';
import Modal from "react-modal";
import coinflip from '../../Assets/coinflip.avif';
import roulette from '../../Assets/roulette.avif';
import crash from '../../Assets/crash.avif';
import dice from '../../Assets/dice.avif';
import slotsSVG from '../../Assets/slots.png';
import EmblaCarousel from '../Slides/EmblaCarousel';
import '../Slides/embla.css';
import '../test.css';
import FrameLauncher from '../iFrame/iFrame';
import euro from '../../Assets/euro.png';
import ProviderCarousel from '../Slides/Providers/ProviderCarousel'; 


// Banner Images
import signupBonus from '../../Assets/banner/signupBonus.png';
import firstDeposit from '../../Assets/banner/firstDepositBonus.png';
import vipBonus from '../../Assets/banner/vipProgram.png';
import weeklyBonus from '../../Assets/banner/weeklyBonus.png';
import crazyTime from '../../Assets/crazyTime.jpg';
import coinFlip from '../../Assets/coinFlip.jpg';


//Slots cards// 
import evoplay from '../../Assets/slots/evoplay.avif';
import habanero from '../../Assets/slots/goldRushHabanero.avif';
import hacksaw from '../../Assets/slots/hacksaw.avif';
import olympus from '../../Assets/slots/olympus.avif';
import pgsoft from '../../Assets/slots/pgsoft.avif';
import playson from '../../Assets/slots/playson.avif';

// Live casino//
import liveCasinoSVG from '../../Assets/chips.png';
import Winnings from '../Table/Winnings';
import { auth } from '../../firebase';
import fwinAvatar from '../../Assets/fwinAvatar.png';


const gameList = [
    {
        "No": 19,
        "Provider": "EVOLUTION",
        "Game_Code": "48z5pjps3ntvqc1b",
        "English_Name": "Auto-Roulette",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/Evolution_auto_roulette.avif"
    },
    {
        "No": 66,
        "Provider": "EVOLUTION",
        "Game_Code": "CrazyTime0000001",
        "English_Name": "Crazy Time",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/evolution_crazytime.avif"
    },
    {
        "No": 52,
        "Provider": "EVOLUTION",
        "Game_Code": "puu47n7mic3rfd7y",
        "English_Name": "Emperor Dragon Tiger",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/evolution_EmperorDragonTiger@2x.avif"
    },
    {
        "No": 67,
        "Provider": "PRAGMATICLIVE",
        "Game_Code": "jzbzy021lg8xy9i2",
        "English_Name": "ANDAR_BAHAR",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/pp_andhar_bahar.avif"
    },
    {
        "No": 92,
        "Provider": "EVOLUTION",
        "Game_Code": "HoldemTable00001",
        "English_Name": "Casino Hold'em",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/evolution_hold_casino.avif"
    },
    {
        "No": 21,
        "Provider": "EZUGI",
        "Game_Code": "32102",
        "English_Name": "Casino Marina Baccarat C",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/ezugi_CasinoMarinaBaccarat3@2x.avif"
    },
    {
        "No": 30,
        "Provider": "EZUGI",
        "Game_Code": "228001",
        "English_Name": "Lucky 7",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/ezugi_lucky7.webp"
    },
    {
        "No": 35,
        "Provider": "EZUGI",
        "Game_Code": "431000",
        "English_Name": "Ruleta del Sol",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/Ezugi_ruleta_del_sol.avif"
    },
    {
        "No": 42,
        "Provider": "PRAGMATICLIVE",
        "Game_Code": "cbcf6qas8fscb221",
        "English_Name": "SPEED_BACCARAT_12",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/pp_speed_baccarat_12.avif"
    },
    {
        "No": 127,
        "Provider": "EVOLUTION",
        "Game_Code": "SuperSicBo000001",
        "English_Name": "Super Sic Bo",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/evolution_super_sicbo.webp"
    },
    {
        "No": 14,
        "Provider": "PRAGMATICLIVE",
        "Game_Code": "rmycjoqq18k2r161",
        "English_Name": "ROULETTE_INDIAN",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/pp_indian_roulette.avif"
    },
    {
        "No": 22,
        "Provider": "PRAGMATICLIVE",
        "Game_Code": "yqpz3ichst2xg439",
        "English_Name": "ROULETTE_MACAO",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/live/pp_macao_roulette.avif"
    },
]

const slotsList = [
    {
        "Provider": "PRAGMATIC",
        "No": 319,
        "Game_Code": "vs20olympx",
        "English_Name": "Gates of Olympus 1000",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/OLYMPUS%201000.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9PTFlNUFVTIDEwMDAuYXZpZiIsImlhdCI6MTcyMjIxODAzMCwiZXhwIjoxNzUzNzU0MDMwfQ.UtEQuaVsaVe9yR4yobKVUgdLB5rLFIV6jpapsvyX63M&t=2024-07-29T01%3A53%3A50.925Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 320,
        "Game_Code": "vswaysbkingasc",
        "English_Name": "Buffalo King Untamed Megaways",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/BUFFALO%20KING%20MEGAWAYS.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9CVUZGQUxPIEtJTkcgTUVHQVdBWVMuYXZpZiIsImlhdCI6MTcyMjIxODA0MSwiZXhwIjoxNzUzNzU0MDQxfQ.RBNKgjsDY-TTe01NmxD-sgKLuxetassiqRzyzLpSAv0&t=2024-07-29T01%3A54%3A01.601Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 321,
        "Game_Code": "vs20sugarrushx",
        "English_Name": "Sugar Rush 1000",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/SUGAR%20RUSH%201000.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9TVUdBUiBSVVNIIDEwMDAuYXZpZiIsImlhdCI6MTcyMjIxODA2MSwiZXhwIjoxNzUzNzU0MDYxfQ.KiJnSl8-vY3qprnXUjuZnb7yNGTaHG9gZEBljPiZ6aE&t=2024-07-29T01%3A54%3A21.350Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 323,
        "Game_Code": "vs20fruitswx",
        "English_Name": "Sweet Bonanza 1000",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/SWEET%20BONANZA%201000.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9TV0VFVCBCT05BTlpBIDEwMDAuYXZpZiIsImlhdCI6MTcyMjIxNzkzMSwiZXhwIjoxNzUzNzUzOTMxfQ.hX_sZ8EtnaMlCLtJv9Ayj-Pa3NTULOKKpemwyPa12iA&t=2024-07-29T01%3A52%3A11.198Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 263,
        "Game_Code": "vs20procount",
        "English_Name": "Wisdom of Athena",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/SUGAR%20RUSH%201000.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9TVUdBUiBSVVNIIDEwMDAuYXZpZiIsImlhdCI6MTcyMjIxODA2MSwiZXhwIjoxNzUzNzU0MDYxfQ.KiJnSl8-vY3qprnXUjuZnb7yNGTaHG9gZEBljPiZ6aE&t=2024-07-29T01%3A54%3A21.350Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 35,
        "Game_Code": "vs40wildwest",
        "English_Name": "Wild West Gold",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/WILD%20WEST.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9XSUxEIFdFU1QuYXZpZiIsImlhdCI6MTcyMjIxNzk2NCwiZXhwIjoxNzUzNzUzOTY0fQ.MvkP30RswYkem4cP3lstcDNOjxN8HuFneBUeJko69Ak&t=2024-07-29T01%3A52%3A44.378Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 286,
        "Game_Code": "vs20lampinf",
        "English_Name": "Lamp Of Infinity",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/LAMP%20OF%20INFINITY.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9MQU1QIE9GIElORklOSVRZLmF2aWYiLCJpYXQiOjE3MjIyMTgwODMsImV4cCI6MTc1Mzc1NDA4M30.nfI2bAfkYpAxcun5uF3sp5Do5NJh9FdSiQWITI4LC6s&t=2024-07-29T01%3A54%3A43.585Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 2,
        "Game_Code": "vs20doghouse",
        "English_Name": "The Dog House",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/THE%20DOG%20HOUSE.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9USEUgRE9HIEhPVVNFLmF2aWYiLCJpYXQiOjE3MjIyMTc5OTAsImV4cCI6MTc1Mzc1Mzk5MH0.UkVyaO8enBBXUv5JR4OYfVbnXygG1xguWSOmtl47Phc&t=2024-07-29T01%3A53%3A10.671Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 25,
        "Game_Code": "vs20midas",
        "English_Name": "The Hand of Midas",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/HAND%20OF%20MIDAS.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9IQU5EIE9GIE1JREFTLmF2aWYiLCJpYXQiOjE3MjIyMTgyODUsImV4cCI6MTc1Mzc1NDI4NX0.xEu51_iS_1rJG7uKyIF9dePe3E7kGA-Jd-q4R-WBlZM&t=2024-07-29T01%3A58%3A05.818Z"
    },
    {
        "Provider": "PRAGMATIC",
        "No": 226,
        "Game_Code": "vs10mmm",
        "English_Name": "Magic Money Maze",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/MAGIC%20MONEY%20MAZE.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9NQUdJQyBNT05FWSBNQVpFLmF2aWYiLCJpYXQiOjE3MjIzMTExNDksImV4cCI6MTc1Mzg0NzE0OX0.UrWNMW02Xd5KeSX4Wl1Rpc4wcsvOsuC6Q-ccsAmI9pk&t=2024-07-30T03%3A45%3A49.460Z"
    },
    {
        "Provider": "PGSOFT",
        "No": 54,
        "Game_Code": "anubis-wrath",
        "English_Name": "Anubis Wrath",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/ANUBIS%20WRATH.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9BTlVCSVMgV1JBVEguYXZpZiIsImlhdCI6MTcyMjMxMTE1OSwiZXhwIjoxNzUzODQ3MTU5fQ.bcWUZwjXPn_i5kWKjkr8WUoW9zlVmPevvPuO1tkZzvw&t=2024-07-30T03%3A45%3A59.598Z"
    },
    {
        "Provider": "PGSOFT",
        "No": 17,
        "Game_Code": "treasures-aztec",
        "English_Name": "Treasures of Aztec",
        "SRC": "https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/sign/slots/TREASURES%20OF%20AZTEC.avif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzbG90cy9UUkVBU1VSRVMgT0YgQVpURUMuYXZpZiIsImlhdCI6MTcyMjMxMTE2OCwiZXhwIjoxNzUzODQ3MTY4fQ.CjB_NcLH9TnKvxEmHKUQRUahvcK0nSYafLMCx2yfDQY&t=2024-07-30T03%3A46%3A08.871Z"
    }
]

const OPTIONS = { dragFree: true, loop: true } 
const SLIDES = [
    signupBonus,
    firstDeposit,
    vipBonus,
    weeklyBonus,
];

class Homepage extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            selectedSlot: null,
            isVisible: false,
            iframeUrl: '',
            region: "",
            isBlocked: false,
            ip: "", // To store the IP address
            countryCode: "", // To store the country code
        };
    }

    handleSlotClick = (slot) => {
        const currentUser = auth.currentUser;

        if (currentUser === null) {
            // Handle case when the user is not logged in
            this.handleGameChange("register");
            return;
        }

        // User is logged in, proceed with API call
        this.setState({
            isVisible: true,
            selectedSlot: {
                id: slot.id,
                url: slot.image,
                provider: slot.provider,
                game_code: slot.Game_Code
            }
        });

        axios.post('https://fwin-backend.onrender.com/api/game-launch', {
            email: currentUser.email, // Use the current user's email
            provider_code: slot.Provider,
            game_code: slot.Game_Code
        })
        .then(response => {
            console.log('Success:', response.data);
            if (response.data.status === 1) {
                this.setState({
                    iframeUrl: response.data.launch_url,
                });
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    closeIframe = () => {
        this.setState({ isVisible: false });
    };

    handleGameChange = (game) => {
        this.props.onGameChange(game);
    };

    componentDidMount() {
        this.fetchLocation();
      }
    
    fetchLocation = async () => {
        try {
          const response = await axios.get("https://ipapi.co/json/");
          const { ip, country_code, continent_code } = response.data;
    
          // Set the IP and country code to state
          this.setState({ ip, countryCode: country_code });
    
          // Check if the visitor is not from the EU
          if (continent_code !== "EU") {
            this.setState({ isBlocked: false });
          } else {
            this.setState({ region: "EU" });
          }
        } catch (error) {
          console.error("Error fetching IP information:", error);
        }
      };
    
    
    

    render() { 
        const { currentPage, isVisible, iframeUrl, region, isBlocked, ip, countryCode  } = this.state;
        return (
            <>
                 <section className='space-y-5'>
                    <EmblaCarousel slides={SLIDES} options={OPTIONS} />
                    <div>
                        <div className='flex'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="6" fill="url(#paint0_linear_4728_6894)"></rect><path d="M17.8108 11.0755C18.5225 11.4864 18.5225 12.5136 17.8108 12.9245L9.27029 17.8554C8.55858 18.2663 7.66895 17.7527 7.66895 16.9308L7.66895 7.06915C7.66895 6.24735 8.55858 5.73371 9.27029 6.14462L17.8108 11.0755Z" fill="#09090B"></path><defs><linearGradient id="paint0_linear_4728_6894" x1="41.6145" y1="-16.5" x2="15.6677" y2="-28.3118" gradientUnits="userSpaceOnUse"><stop offset="0.104167" stop-color="#FF4E4E"></stop><stop offset="0.380208" stop-color="#FFDB5B"></stop><stop offset="0.635417" stop-color="#58F57B"></stop><stop offset="0.916667" stop-color="#4690FF"></stop></linearGradient></defs></svg>
                            <span className='font-sans text-md font-bold px-2'>Fwin Originals</span>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 justify-between gap-4 md:grid-cols-4'> 
                       <div className='relative'>
                        <a onClick={() => this.handleGameChange('homepage')}><img alt="Coinflip banner" loading="lazy" width="3630" height="1816" decoding="async" data-nimg="1" class="rounded-md cursor-pointer" src={coinflip} />
                            <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-md cursor-pointer'>
                                <span className='text-white text-2xl font-bold'>Country Restriction</span>
                            </div>
                        </a>
                       </div>
                        <div className='relative'>
                        <a onClick={() => this.handleGameChange('')}><img alt="Coinflip banner" loading="lazy" width="3630" height="1816" decoding="async" data-nimg="1" class="rounded-md cursor-pointer" src={roulette} /></a>
                            <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-md cursor-pointer'>
                                <span className='text-white text-2xl font-bold'>Country Restriction</span>
                            </div>
                        </div>
                        <div className='relative'>
                        <a onClick={() => this.handleGameChange('')}><img alt="Coinflip banner" loading="lazy" width="3630" height="1816" decoding="async" data-nimg="1" class="rounded-md cursor-pointer" src={crash} /></a>
                             <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-md cursor-pointer'>
                                <span className='text-white text-2xl font-bold'>Country Restriction</span>
                        </div>
                        </div>

                        <div className='relative'>
                        <a onClick={() => this.handleGameChange('')}><img alt="Coinflip banner" loading="lazy" width="3630" height="1816" decoding="async" data-nimg="1" class="rounded-md cursor-pointer" src={dice} /></a>
                        <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-md cursor-pointer'>
                                <span className='text-white text-2xl font-bold'>Country Restriction</span>
                        </div>
                        </div>
                    </div>
                    <div className='pt-4'>
                        <div className='flex'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="6" fill="#FF5733"></rect>
                            </svg>
                            <span className='font-sans text-md font-bold px-2'>Slots Providers</span>
                        </div>
                    </div>
                    <ProviderCarousel /> 
                    <div className='pt-4'>
                        <div className='flex'>
                            <div className='flex' onClick={() => this.handleGameChange('slots')}>
                            <img className="cursor-pointer rounded-md" src={slotsSVG} height={24} width={24}/>
                            <span className='cursor-pointer font-sans text-md font-bold px-2'>Slots</span>
                            </div>
                            <div className='grow'>
                                <div className='flex justify-end'>
                                <div>
                                <span className='cursor-pointer text-[#3230c4] font-bold' onClick={() => this.handleGameChange('slots')}>All 839</span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pb-4">
                        <div className="grid grid-cols-3 gap-4 lg:grid-cols-6 justify-between">
                            {slotsList.map((game, index) => (
                                <div
                                    key={index}
                                    className={`sm:h-[164px] md:h-[246px] cursor-pointer flex items-center justify-center shadow-md rounded-lg overflow-hidden ${index % 3 === 0 ? 'bg-blue-500' : index % 3 === 1 ? 'bg-red-500' : 'bg-green-500'}`}
                                    onClick={() => this.handleSlotClick(game)}
                                >
                                    <img src={game.SRC} alt={game.English_Name} className='object-cover h-full w-full' />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='flex' onClick={() => this.handleGameChange('live')}>
                            <img className="cursor-pointer rounded-md" src={liveCasinoSVG} height={24} width={24}/>
                            <span className='cursor-pointer font-sans text-md font-bold px-2'>Live Casino</span>
                            </div>
                            <div className='grow'>
                                <div className='flex justify-end'>
                                    <div>
                                <span className='cursor-pointer text-[#3230c4] font-bold' onClick={() => this.handleGameChange('live')}>All 262</span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pb-4">
                        <div className="grid grid-cols-3 gap-4 lg:grid-cols-6 justify-between">
                            {gameList.map((game, index) => (
                                <div
                                    key={index}
                                    className={`sm:h-[164px] md:h-[246px] cursor-pointer flex items-center justify-center shadow-md rounded-lg overflow-hidden ${index % 3 === 0 ? 'bg-blue-500' : index % 3 === 1 ? 'bg-red-500' : 'bg-green-500'}`}
                                    onClick={() => this.handleSlotClick(game)}
                                >
                                    <img src={game.SRC} alt={game.English_Name} className='object-cover h-full w-full' />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="6" fill="#003A25"></rect><circle cx="12" cy="12" r="3" fill="#58F57B" class="animate-pulse"></circle></svg>
                            <span className='font-sans text-md font-bold px-2'>Bets</span>
                        </div>
                    </div>
                    <div className='relative h-[400px] overflow-y-auto rounded-md border'>
                       <Winnings />
                    </div>
                 </section>
                <section class="mt-5 space-y-5"><div class="flex items-center gap-2">
                    <div class="flex h-6 w-6 items-center justify-center rounded-md border"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bar-chart4 h-4 w-4"><path d="M3 3v18h18"></path><path d="M13 17V9"></path><path d="M18 17V5"></path><path d="M8 17v-3"></path></svg></div>
                    <h3>Analytics</h3></div>
                    <div class="hide-scroll-bar flex items-center gap-5 overflow-x-auto">
                        <div class="w-full min-w-48 space-y-2 rounded-md border p-5">
                            <div class="flex items-center gap-1 text-muted-foreground">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign rounded-md bg-border p-1"><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                <h3>Volume</h3>
                            </div>
                            <div class="text-2xl">
                                <span class="flex items-center gap-1">8,927,995<img src={euro} width={24}></img></span>
                            </div>
                        </div>
                        <div class="w-full min-w-48 space-y-2 rounded-md border p-5">
                            <div class="flex items-center gap-1 text-muted-foreground"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trophy rounded-md bg-border p-1"><path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path><path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path><path d="M4 22h16"></path><path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path><path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path><path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"></path></svg>
                            <h3>Bets</h3>
                            </div>
                            <div class="text-2xl"><span class="flex items-center gap-1">1,197,909</span>
                            </div>
                        </div>
                        <div class="w-full min-w-48 space-y-2 rounded-md border p-5">
                                <div class="flex items-center gap-1 text-muted-foreground"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-user-round rounded-md bg-border p-1"><path d="M18 20a6 6 0 0 0-12 0"></path><circle cx="12" cy="10" r="4"></circle><circle cx="12" cy="12" r="10"></circle></svg><h3>Players</h3>
                            </div>
                            <div class="text-2xl">
                                <span class="flex items-center gap-1">8,383</span></div></div>
                        </div>
                </section>
                {isVisible && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50">
                        <FrameLauncher iframeUrl={iframeUrl} closeIframe={this.closeIframe} />
                    </div>
                )}
                <Modal
                    isOpen={isBlocked}
                    style={{
                        content: {
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            padding: "0px",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                            backgroundColor: "#030c2f",
                            borderRadius: "20px",
                            zIndex:"100",
                         
                        },
                    }}
                >  
                   <div className='w-[80vw] h-fit md:h-[70vh] md:w-[30vw] rounded-xl overflow-hidden z-20 text-white py-4 lg:p-2'>
                    <div className='flex flex-col justify-center'>
                        <div className='flex justify-center'>
                            <img src={fwinAvatar} width="80%" />
                        </div>
                        <div>
                        <h1 className='font-sans text-2xl'>Gaming Denied</h1>
                        <p className='font-sans text-sm'>Unfortunately,&nbsp;Fwin is not<br></br> available in your region
                        </p>
                        <p className='font-sans text-xs px-16 font-light text-gray-500 py-4'>
                         If you are using a VPN or proxy service, try to turn it off to be able to log in. For any questions, please contact our customer support service
                        </p>
                        </div>
                        <div className='flex justify-center font-sans text-xs px-16 font-light text-gray-300 pb-4 md:pb-0'>
                            <p>
                             {ip}&nbsp;{countryCode}
                            </p>
                        </div>
                    </div>
                   </div>
                </Modal>
            </>
        );
    }
}
 
export default Homepage;