import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../Components/Auth/AuthContext';  
import Datepicker from "tailwind-datepicker-react"

const options = {
	autoHide: true,
	todayBtn: false,
	clearBtn: true,
	clearBtnText: "Clear",
	maxDate: new Date("2030-01-01"),
	minDate: new Date("1950-01-01"),
	theme: {
		background: "bg-white",
		todayBtn: "",
		clearBtn: "",
		icons: "",
		text: "",
		disabledText: "",
		input: "",
		inputIcon: "",
		selected: "",
	},
	icons: {
		// () => ReactElement | JSX.Element
		prev: () => <span><svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg></span>,
		next: () => <span><svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></span>,
	},
	datepickerClassNames: "top-10 right-0",
	defaultDate: new Date("2022-01-01"),
	language: "en",
	disabledDates: [],
	weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
	inputNameProp: "date",
	inputIdProp: "date",
	inputPlaceholderProp: "Select Date",
	inputDateFormatProp: {
		day: "numeric",
		month: "long",
		year: "numeric"
	}
}
// Function to handle tab change


const Wagering = () => {
    const { fwinBackendUrl} = useAuth();
    const [show, setShow] = useState(false);
    const [date, setDate] = useState();
    const [activeTab, setActiveTab] = useState('all');
    const [summary, setSummary] = useState({
        signup: 0,
        first: 0,
        deposit: 0,
      });
    const [allBonuses, setAllBonuses] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [isCheckedSignUp, setIsCheckedSignUp] = useState(false)
    
    useEffect(() => {
        fetch(`${fwinBackendUrl}/bonus/summary`)  // Replace with your API endpoint
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            setSummary(data);  // Save the response data to state
            const totalBonuses = data.signup + data.first + data.deposit;
            setAllBonuses(totalBonuses);  // Set the total sum in state
          })
          .catch((error) => {
          });
      }, []);
    useEffect(() => {
        fetch(`${fwinBackendUrl}/bonus/all`)  // Replace with your actual API endpoint
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to fetch transactions');
            }
            return response.json();
          })
          .then((data) => {
            setTransactions(data);  // Save the response data to the state

          })
          .catch((err) => {

          });
      }, []);

	const handleChange = (selectedDate) => {
		setDate(selectedDate);
	}
	const handleClose = (state) => {
		setShow(state)
	}
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        // Reset pagination when switching tabs 
    };
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Extract YYYY-MM-DD
    };
    const filteredTransactions = transactions.filter((item) => {
      const matchesType =
        activeTab === 'all' ? true : item.type === activeTab;
      const matchesDate = date
        ? formatDate(item.createdAt) === formatDate(date)
        : true;
      return matchesType && matchesDate;
    });
    const handleCheckboxChangeSignUp = () => {
        setIsCheckedSignUp(!isCheckedSignUp)
      }
    


    return (
        <div className="flex flex-col gap-8">
            <section>
                <div className='space-y-5'>
                    <div className="flex items-center gap-2">
                        <div className="flex h-6 w-6 items-center justify-center rounded-md border">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-club h-4 w-4"
                            >
                                <path d="M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z"></path>
                                <path d="M12 17.66L12 22"></path>
                            </svg>
                        </div>
                        <h3>Wagering System</h3>
                    </div>
                </div>
           </section>
           <section>
            <div className='bg-white border border-gray-200 rounded-lg shadow py-4'>
                <div className='flex flex-col gap-8 sm:flex-row'>
                        <div className='p-6 sm:w-1/2 flex flex-col space-y-4'>
                        <h3 className='text-left pb-4'>General Bonuses</h3>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>Signup Bonus</p>
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                            €&nbsp;10.00
                            </span>
                        </div>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>First Deposit Bonus wagering</p>
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                            250%
                            </span>
                        </div>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>Deposit Bonus wagering</p>
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                            100%
                            </span>
                        </div>

                        </div>
                        <div className='p-6 sm:w-1/2 flex flex-col space-y-4'>
                        <h3 className='text-left pb-4'>Wagering Multiplier</h3>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>Signup Bonus wagering</p>
                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">20x</span>
                            
                        </div>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>First Deposit Bonus Wagering</p>
                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">25x</span>
                        </div>
                        <div className='flex gap-4 justify-between'>
                            <p className='text-sm font-sans'>Deposit Bonus wagering</p>
                            <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">15x</span>
                        </div>
                    </div>
                </div>
            </div>
           </section>
           
        </div>
    );
};

export default Wagering;