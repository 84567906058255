import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { createUserWithEmailAndPassword,sendEmailVerification, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth"; 
import ProfileSidebar from './ProfileSidebar';
import { useAuth } from './AuthContext'; 
import bonus from '../../Assets/signupPageBonus.png';

const Register = ({ onGameChange }) => {
  const { fwinBackendUrl } = useAuth();
  const [ country, setCountry ] = useState('');
  const [isLogin, setIsLogin] = useState(true); 
  const [isDeposit, setIsDeposit] = useState(true); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [user, setUser] = useState(null);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [uuid, setUuid] = useState("");
  const [message, setMessage] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [withoutBonus, setWithoutBonus] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  

  const handleGameChange = () => {
    onGameChange("homepage");
  };
  // firebase auth
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (user) {
        const uid = user.uid;
        setUuid(uid)
    }
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user.emailVerified) {
        setSuccess('Login successful!');
    } else {
      await signOut(auth);
      setError('Please verify your email before logging in.');
    }
    } catch (error) {
      setError(error.message);
      setSuccess('');
    }
  };

  const handleRegister = async () => {
    if (!agreeToTerms) {
      setError('You must agree to the terms and conditions.');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      // Create user with Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      

      // Update uuid directly from the created user
      const firstName = firstname;
      const lastName = lastname;
      const Country = country;
      

      // Create user in backend and notify external API
      await createUserApi(email, Country , firstName, lastName, withoutBonus );

      setSuccess('Registered successfully!');
      setError('');
    } catch (error) {
      setError(error.message);
      setSuccess('');
    }
  };
  
  const handleGoogleSignIn = async () => {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        // User information
        const user = result.user;
        console.log("User Info:", user);
      } catch (error) {
        console.error("Error during Google Sign-In:", error);
      }
  };

  const handleLogout = async () => {
    await signOut(auth);
  };
  // Function to create user
  const createUserApi = async (email, Country, firstName, lastName, withoutBonus ) => {
    try { 
      // Step 1: Create the user
      const createUserResponse = await fetch(`${fwinBackendUrl}/payments/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, Country, firstName, lastName, withoutBonus }),
      });
  
      if (!createUserResponse.ok) {
        throw new Error(`Failed to create user: ${createUserResponse.statusText}`);
      }
      window.location.reload()
    } catch (error) {
      console.error('Error during user creation', error.message);
      throw error;
    }
  };
  

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Check your inbox.");
    } catch (err) {
      setError("Error sending reset email. Please make sure the email is correct.");
    }
  };

  const handleToggle = () => {
    setWithoutBonus((prev) => !prev);
  };
  
  



  if (user) {
    return <Profile  handleLogout={handleLogout} isDeposit={isDeposit} setIsDeposit={setIsDeposit} />;
  }

  return (
    <div className="w-full">
      <div className='flex justify-end items-center bg-gray-800 w-full'>
        <div onClick={handleGameChange}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-8">
            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg>
        </div>
      </div>
      <div className=" px-2 py-8">
        <div className="flex space-x-4 border-b border-slate-200">
          <div
            className={`flex-1 flex items-center justify-center cursor-pointer ${isLogin ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
            onClick={() => setIsLogin(true)}
          >
            <span className='font-bold'>Login</span>
          </div>
          <div
            className={`flex-1 flex items-center justify-center cursor-pointer ${!isLogin ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
            onClick={() => setIsLogin(false)}
          >
            <span className='font-bold'>Join</span>
          </div>
        </div>
        <div className='form-container pt-12'>
        {showForgotPassword ? (
          <ForgotPasswordForm
          email={email}
          setEmail={setEmail}
          error={error}
          handleForgotPassword = {handleForgotPassword}
          message = {message}
          />
         ):
          isLogin ? (
            <LoginForm
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              handleLogin={handleLogin}
              error={error}
              success={success}
              onForgotPassword={() => setShowForgotPassword(true)}
            />
          ) : (
            <RegisterForm
              email={email}
              password={password}
              confirmPassword={confirmPassword}
              setEmail={setEmail}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              handleRegister={handleRegister}
              error={error}
              success={success}
              handleGoogleSignIn={handleGoogleSignIn}
              firstname={firstname}
              lastname={lastname}
              setFirstname={setFirstname}
              setLastname={setLastname}
              country={country}
              setCountry={setCountry}
              handleToggle={handleToggle}
              withoutBonus={withoutBonus}
              setAgreeToTerms={setAgreeToTerms}
              agreeToTerms={agreeToTerms}

            />
          )}
        </div>
      </div>
    </div>
  );
  } 

const Profile = () => { 

  

  return (
    <>
    <ProfileSidebar  />
    </>
  );
};

function LoginForm({ email, password, setEmail, setPassword, handleLogin, error, success, onForgotPassword }) {
  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Login</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your password"
          />
        </div>
        <button
          type="button"
          onClick={handleLogin}
          className="w-full bg-[#3230C4] text-white p-2 rounded hover:bg-blue-600"
        >
          Login
        </button>
        <p className='text-right cursor-pointer pt-4 text-blue-600'
        onClick={onForgotPassword}
        >forgot password</p>
      </form>
    </div>
  );
}

function RegisterForm({ email, password, confirmPassword, setEmail, setPassword, setConfirmPassword, handleRegister, error, success, handleGoogleSignIn, firstname, lastname, setFirstname, setLastname, country, setCountry , withoutBonus, handleToggle, agreeToTerms, setAgreeToTerms }) {
  return (
    <>
    <section>
      <div className='pb-6 max-w-md mx-auto'>
        <img src={bonus} className='w-full rounded-lg' /> 
      </div>
    </section>
    <section>
    <div className="max-w-md mx-auto p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Register</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your password"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="confirm-password">Confirm Password:</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Confirm your password"
          />
        </div>
        <div class="mb-4 grid md:grid-cols-2 md:gap-6">
          <div class="relative z-0 w-full mb-5 group">
            <input 
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            type="text" name="floating_first_name" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First name</label>
          </div>
          <div class="relative z-0 w-full mb-5 group">
            <input 
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            type="text" name="floating_last_name" id="floating_last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label for="floating_last_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last name</label>
          </div>
        </div>
        <div className='flex flex-col mb-6'>
            <label for="countries" class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your country</label>
            <select 
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

            <option value="">Select a country</option>
            <option value="Austria">Austria</option>
            <option value="Belgium">Belgium</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Estonia">Estonia</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="Germany">Germany</option>
            <option value="Greece">Greece</option>
            <option value="Ireland">Ireland</option>
            <option value="Italy">Italy</option>
            <option value="Latvia">Latvia</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Malta">Malta</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Portugal">Portugal</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Spain">Spain</option>
          </select>
        </div>
        <div className='flex pt-2'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
            </svg>
          </div>
          <div className='px-2'>
            Welcome Bonus
          </div>
          <div className='flex-1'>
            <div className='flex flex justify-end'>
              <div className='text-sm text-gray-500 px-2'>Without bonus </div>
              <label class="inline-flex items-center mb-5 cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer"
                checked={withoutBonus}
                onChange={handleToggle}
                 />
                <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
              </label>
            </div>
          </div>
        </div>
        {!withoutBonus && (
        <div className='flex pb-4 '>
          <div className=' w-full bg-green-100 rounded-sm border border-1 border-green-300 font-thin'>
          €10.00 Signup Bonus
          </div>
        </div>
         )}
        <div class="flex items-start py-5">
          <div class="flex items-center h-5">
            <input
             checked={agreeToTerms}
             onChange={(e) => setAgreeToTerms(e.target.checked)}
             id="terms" type="checkbox" value="" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
          </div>
          <label for="terms" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I am 18+ and i agree <a href="#" class="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a></label>
        </div>
        <button
          type="button"
          onClick={handleRegister}
          className="w-full bg-[#3230C4] text-white p-2 rounded hover:bg-green-600 mb-4"
        >
          Register
        </button>
      </form>
    </div>
    </section>
    </>
    
  );
}

function ForgotPasswordForm({ email, setEmail, error, handleForgotPassword, message }) {
  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {message && <p className="text-green-500 mb-4">{message}</p>}
      <form onSubmit={handleForgotPassword}>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your email"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-[#3230C4] text-white p-2 rounded hover:bg-blue-600"
        >
          Send Password Reset Email
        </button>
      </form>
    </div>
  );
}

export default Register;
