import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar'; 
import { Routes ,Route } from 'react-router-dom';
import Sandbox from './Components/Sandbox';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Auth from './Components/Auth/Register';
import Loader from './Components/Loader';
import { AuthProvider } from './Components/Auth/AuthContext';
import Admin from '../src/Admin/Admin';
import ServerStatus from './Components/ServerStatus';



const App = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if the page is loading for the first time
    if (!localStorage.getItem('firstLoadDone')) {
      setLoading(true);
      localStorage.setItem('firstLoadDone', 'true');
    }
  }, []);

  const handleLoaderFinish = () => {
    setLoading(false);
  };


  
  return (
    <AuthProvider>
      <div className="App">
        {loading && <Loader onLoaded={handleLoaderFinish} />}
        {!loading && (
          <Routes>
            <Route path='/' element={<Navbar />} />
            <Route path='/Admin' element={<Admin />} />
            <Route path='/status/server' element={<ServerStatus />} />   
            <Route path='/test' element={<Sandbox />} />
          </Routes>
        )} 
      </div> 
    </AuthProvider>
  );
}

export default App;
