import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, database } from '../../firebase';
import { ref, onValue, set, serverTimestamp, onDisconnect, runTransaction, get, getDatabase } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';

const AuthContext = createContext();  

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("EUR");
  const [fwinBackendUrl, setFwinBackendUrl] = useState("https://fwin-backend.onrender.com");
  const [activeUsers, setActiveUsers] = useState(0);
  const [activeUserIds, setActiveUserIds] = useState([]);
  const [userDetails, setUserDetails] = useState({
    userid: "",
    firstName: "",
    lastName: "",
    userCountry: "",
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      
      if (user) {
        setEmail(user.email);
        setCurrentUser(user);
        fetchUserBalance(user.email);
        fetchUserInfo(user.email);
      } else {
        setEmail("");
        setCurrentUser(null);
        setBalance(0);
        setUserDetails({
          userid: "",
          firstName: "",
          lastName: "",
          userCountry: "",
        });
      }
    });


    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    const handleUserPresence = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // If no user is logged in, return early and do nothing
        return;
      }
  
      const db = getDatabase();
      const activeUsersRef = ref(db, 'activeUsers');
      const usersRef = ref(db, 'users');
  
      // Function to update active users count
      const updateActiveUsersCount = async () => {
        try {
          const snapshot = await get(usersRef);
          let onlineCount = 0;
          snapshot.forEach((childSnapshot) => {
            const userStatus = childSnapshot.val();
            if (userStatus.online) {
              onlineCount += 1;
            }
          });
          console.log('Setting active users count to:', onlineCount);
          await set(activeUsersRef, onlineCount);
          setActiveUsers(onlineCount); // Update state here
        } catch (error) {
          console.error('Error updating active users count:', error);
        }
      };
  
      // Listen for changes in the users node
      const usersRefListener = onValue(usersRef, () => {
        updateActiveUsersCount();
      });
  
      // Generate a unique session ID for each visitor
      let sessionId = localStorage.getItem('sessionId');
      if (!sessionId) {
        sessionId = uuidv4(); // Generate a new UUID if none exists
        localStorage.setItem('sessionId', sessionId);
      }
  
      const userStatusRef = ref(db, `users/${sessionId}`);
  
      // Fetch the user's IP and country
      const fetchIpAndCountry = async () => {
        try {
          const response = await fetch('https://api.ipify.org?format=json');
          const { ip } = await response.json();
  
          // Use a GeoIP API to find the country
          const geoResponse = await fetch(`https://ipapi.co/${ip}/json/`);
          const geoData = await geoResponse.json();
  
          return { ip, country: geoData.country_name || 'Unknown' };
        } catch (error) {
          console.error('Error fetching IP or country:', error);
          return { ip: 'Unknown', country: 'Unknown' };
        }
      };
  
      // Mark the visitor as online and update their status
      fetchIpAndCountry().then(({ ip, country }) => {
        const loginTimestamp = serverTimestamp();
  
        set(userStatusRef, {
          online: true,
          lastChanged: loginTimestamp,
          isGuest: !auth.currentUser, // Mark as guest if no user is logged in
          email: auth.currentUser?.email || null, // Store email if logged in
          uid: auth.currentUser?.uid || sessionId, // Store UID or sessionId
          ip,
          country,
          loginTimestamp,
        })
          .then(() => {
            // Update active users count on initial connection
            updateActiveUsersCount();
          })
          .catch((error) => {
            console.error('Error setting user status:', error);
          });
  
        // Handle disconnection
        onDisconnect(userStatusRef)
          .set({
            online: false,
            lastChanged: serverTimestamp(),
            isGuest: !auth.currentUser,
            durationInMinutes: calculateSessionDuration(loginTimestamp),
          })
          .then(() => {
            // Update active users count on disconnection
            updateActiveUsersCount();
          })
          .catch((error) => {
            console.error('Error handling disconnection:', error);
          });
      });
  
      // Function to calculate session duration in minutes
      const calculateSessionDuration = (startTimestamp) => {
        if (!startTimestamp) return 0; // Return 0 if timestamp is missing or invalid
      
        // Handle Firebase Timestamp objects or plain date strings
        const startMillis =
          typeof startTimestamp === "object" && startTimestamp.seconds
            ? startTimestamp.seconds * 1000
            : new Date(startTimestamp).getTime();
      
        if (isNaN(startMillis)) return 0; // Fallback to 0 if the calculation fails
      
        const endMillis = Date.now(); // Current time in milliseconds
        return Math.max(0, Math.round((endMillis - startMillis) / 60000)); // Ensure non-negative duration
      };
  
      return () => {
        usersRefListener(); // Clean up the listener on component unmount
      };
    });
  
    // Cleanup the auth listener on component unmount
    return () => {
      handleUserPresence();
    };
  }, []);
  useEffect(() => {
        fetchUserInfo(email);
  }, [email]);

  const fetchUserInfo = async (email) => {
      try {
        const response = await fetch(`${fwinBackendUrl}/payments/user-info/${email}`);
        
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        
        const data = await response.json();
        const { userid, firstName, lastName, userCountry } = data;
  
        setUserDetails({
          userid,
          firstName,
          lastName,
          userCountry,
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

  const fetchUserBalance = async (userEmail) => {
    const payload = { email: userEmail };

    try {
      const response = await fetch(`${fwinBackendUrl}/api/user-balance`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        setBalance(data);
        sync(userEmail);
      } else {
        console.error('Failed to fetch balance:', data.msg);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const sync = async (email) => {
    try {
      await fetch(`${fwinBackendUrl}/api/sync`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      console.log('Synchronized');
    } catch (err) {
      console.error('Error during balance synchronization:', err);
    }
  };

  const updateBalance = () => {
    if (currentUser) {
      fetchUserBalance(currentUser.email);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, balance, email, userDetails, country, fwinBackendUrl, activeUsers,  fetchUserBalance, updateBalance }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
